import React, { useRef, useState } from 'react';
import './IsDeveloping.scss';

function IsDeveloping() {
    const infoRef = useRef(null);
    const [transformValue, setTransformValue] = useState(0);
    const [isActive, setIsActive] = useState(false);

    const handleToggle = () => {
        const infoElement = infoRef.current;

        if (infoElement) {
            if (isActive) {
                setTransformValue(0);
            } else {
                const height = infoElement.offsetHeight;
                setTransformValue(height + 20);
            }
            setIsActive(!isActive);
        }

        document.querySelector('.is-developing-container').classList.toggle('activeInfo');
        infoElement.classList.toggle('activeInfo');
    };

    return (
        <>
            <div
                className="is-developing-container"
                style={{ transform: `translateY(-${transformValue}px)` }}
            >
                <div>
                    <h5>Этот сайт еще разрабатывается...</h5>
                    <p>
                        Планируемая дата сдачи <b>16.01.2025</b>
                    </p>

                    <div className="btn-is-developing" onClick={handleToggle}>
                        Подробнее
                    </div>
                </div>
                <div className="spinner"></div>
            </div>

            <div className="is-developing-info" ref={infoRef}>
                <h5>Степень готовности элементов:</h5>

                <div className="ready-item">
                    <div className="name">Контент:</div>
                    <div className="progress-bar">
                        <div className="progress" style={{ width: '70%' }}></div>
                    </div>
                    <div className="value">70%</div>
                </div>

                <div className="ready-item">
                    <div className="name">Вёрстка:</div>
                    <div className="progress-bar">
                        <div className="progress" style={{ width: '100%' }}></div>
                    </div>
                    <div className="value">100%</div>
                </div>

                <div className="ready-item">
                    <div className="name">Рекламные инструменты:</div>
                    <div className="progress-bar">
                        <div className="progress" style={{ width: '70%' }}></div>
                    </div>
                    <div className="value">70%</div>
                </div>

                <div className="ready-item">
                    <div className="name">Мобильная версия:</div>
                    <div className="status">
                        {false ? (
                            <span className="true">Готово</span>
                        ) : (
                            <span className="false">Не готово</span>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}

export default IsDeveloping;
