//CaseInfoAboutProduct.js
import React from 'react'

function CaseInfoAboutProduct({backgroundColor}) {
    return (
        <>
            <div className="block-background--case-color " style={{backgroundColor: backgroundColor}}>
                <div className="block-wrapper block-wrapper--quote" >
                    <h2 className='tp-bold'>Что вообще такое корпоративный стиль?</h2>
                    <p className='tp-normal'>
                        Корпоративный стиль - это визуальный образ компании, благодаря которому компания выделяется среди других участников рынка и основа всей
                        коммуникационной базы компании. По основным элементам фирменного стиля покупатели узнают и различают бренды.
                    </p>
                    <br />
                    <h2 className='tp-bold'>Что такое корпоративный дресс-код?</h2>
                    <p className='tp-normal'>
                        Корпоративный дресс-код - это система стандартов внешнего вида для сотрудников компании, включающая требования к одежде, обуви,
                        аксессуарам, а также к прическе, макияжу и оформлению образа в целом.
                    </p>
                    <p className='tp-thin'>
                        Он является существенным элементом корпоративной культуры в целом, тесно связан со спецификой бизнеса, стратегией, ценностями, стилем
                        управления, системой отношений с клиентами и партнерами.
                    </p>
                    <p className='tp-thin'>
                        Дресс –код важный компонент имиджа сотрудников, помогает идентифицировать компанию, поддерживать нужный имидж, управлять впечатлением,
                        производимым на партнеров и клиентов, идентифицировать статус сотрудника, регулировать внутреннюю стабильность и психологическое
                        состояние сотрудников.
                    </p>
                </div>
            </div>
        </>
    )
}

export default CaseInfoAboutProduct
